// *****************************************
// NAVIGATION COMPONENTS
// *****************************************

// ========================================
// HEADER
// ========================================
header#header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	color: $grey-dark;
	padding-bottom: $base-margin/2;
	padding-top: $base-margin/2;
	box-shadow: 0 2px 1px rgba(#000,0.15);
	z-index: 3000;
	.admin-bar & { top: 32px; }
	a {
		color: $grey-dark;
		text-decoration: none;
	}

	.col-logo {
		flex: 0 0 160px;
		max-width: 160px;
		margin-bottom: 1rem;
		transition: all 0.2rem;
	}

	div.menu {
		clear: both;
		line-height: $lh-compact;
		&.main-menu {
			margin-top: $base-margin;
			display: none;
		}
		ul.menu {
			@extend %list-inline;
			margin: 0 -$base-margin/4;
			padding: 0;
			li {
				margin: 0 $base-margin/4;
				padding: 0;
				position: relative;
				a {
					display: inline-block;
					font-family: $headline;
					font-size: 1.1rem;
					font-weight: 500;
					color: #888;
					letter-spacing: 2px;
				}
				ul {
					margin: 0;
					padding: 0;
					li {
						margin: 0;
						padding: 0;
						a {
							display: block;
						}
					}
				}
			}
		}
	}

	div.menu-utility-menu-container {
		ul.menu {
			margin-left: -$base-margin/2;
			margin-right: -$base-margin/2;
			li {
				font-size: 2rem;
				margin-left: $base-margin/2;
				margin-right: $base-margin/2;
				display: none;
				font-weight: $bold;
				&.icon-navigation,
				&.icon {
					display: inline-block;
					a {
						display: block;
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						opacity: 0;
						overflow: hidden;
						z-index: 5;
					}
				}
			}
		}
	}

	div.menu-main-menu-container {
		ul.menu {
			li {
				text-transform: uppercase;
				a {
					&:after {
						position: relative;
						left: 50%;
						margin: 7px 0 0 -20px;
						display: block;
						width: 40px;
						height: 5px;
						content: '';
						background: $white;
					}//&:after
				}
				&.current-menu-item {
					a {
						// color: #333;
						&:after { background: #888; }
					}//a
					ul li a {color: $white;}
				}
				&:hover {
					> a {
						color: #333;
						&:after { background: #888; }
					}//> a
					ul {
						visibility: visible;
						opacity: 1;
					}
				}
				ul {
					position: absolute;
					top: 100%;
					left: 50%;
					width: 300px;
					transform: translateX(-50%);
					// padding-top: $base-margin;
					margin-top: -2px;
					visibility: hidden;
					opacity: 0;
					z-index: 99;
					background: rgba(#000,0.75);
					li {
						display: block;
						text-align: left;
						text-transform: none;
						&:last-child {border-bottom: none;}
						ul {display: none !important;}
						&.current-menu-item a { background: $grey-dark; }
						a {
							position: relative;
							display: block;
							padding-bottom: 20px;
							font-family: $sans-serif;
							color: $base-cta-text;
							padding: 0.875rem 1rem;
							font-size: 16px;
							&:after { display: none; }
							&:hover {
								background: $grey-dark;
								color: $base-cta-text;
							}
						}
					}
				}
			}
		}
	}

	@include breakpoint(xsm) {

	}//xsm

	@include breakpoint(sm) {
		div.menu-utility-menu-container {
			ul.menu {
				li {
					&.icon {
						&.icon-phone {
							&:before { padding: 5px 5px 0 0; }
							a {
								display: inline-block;
								vertical-align: top;
								position: relative;
								margin-top: 7px;
								color: $primary;
								opacity: 1;
							}//a
						}//&.icon-phone
					}//&.icon
				}//li
			}//ul.menu
		}//div.menu-utility-menu-container
	}//sm

	@include breakpoint(md) {

	}//md

	@include breakpoint(mdlg) {

	}//mdlg

	@include breakpoint(lg) {
		padding-bottom: $base-margin / 1.2;
		padding-top: $base-margin;

		div.menu {
			&.main-menu {display: block;}
			ul.menu {
				li {
					&.mobile-menu-open {display: none;}
				}
			}
		}

		div.menu-utility-menu-container {
			ul.menu {
				li {
					display: inline-block;
					font-size: inherit;
					a { color: $primary; }
					&.icon {
						&:before {
							margin: 6px 5px 0 0;
							color: $primary;
						}
						a {
							display: inline-block;
							position: static;
							top: auto;
							bottom: auto;
							left: auto;
							right: auto;
							opacity: 1.0;
							overflow: visible;
						}
					}
				}
			}
		}

		.col-logo {
			flex: 0 0 260px;
			max-width: 260px;
		}

		// Three rules below used for scroll effect
		// position: absolute;
		margin-top: 255px;
		transform: translateX(0) translateY(-255px);

		// Scroll Trigger Start
		&.scroll {
			position: fixed;
			backface-visibility: hidden;
			transition: transform .5s ease-in-out;
			margin-top: 0;
			transform: translateX(0) translateY(0);

		}//scroll trigger

	}//lg

	@include breakpoint(xlg) {
		div.menu {
			ul.menu {
				li {
					a { font-size: 1.3rem; }
				}
			}
		}
	}//xlg

}//header#header

// ========================================
// HEADER OVERLAY
// ========================================
#header-overlay {
	display: none;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 3001;
	background: rgba($black,0.9);
	color: $white;

	a {
		color: $white;
	}

	#overlay-close {
		display: none;
	}

	.wrap {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: auto;
		width: 100%;
		overflow-x: hidden;
		overflow-y: scroll;
		background: lighten($black,25%);
		z-index: 99;
	}

	.row.top {
		padding-bottom: $base-margin;
		padding-top: $base-margin;
		line-height: 1;
		margin-bottom: 0;
		.col {margin-bottom: 0;}
		.col-left {
			flex-basis: 6.250rem;
			max-width: 6.250rem;
		}
	}

	#burger-close {
		font-size: 1.750rem;
		display: inline-block;
		width: 5rem;
		text-align: center;
		margin-left: auto;
		margin-right: -$base-margin;
		line-height: 1;
		cursor: pointer;
	}

	.mobile-utility-menu {
		ul.menu {
			@extend %list-block;
			li {
				border-bottom: 1px solid $white;
				position: relative;
				a {
					display: block;
					padding: $base-margin;
					font-weight: $bold;
					text-decoration: none;
				}
				&.mobile-menu-open {
					display: none;
				}
				&.icon {
					a {
						min-height: 1rem;
						padding-left: $base-margin + 1.75rem + 0.63rem;
					}
					&:before {
						display: block;
						pointer-events: none;
						width: 1.75rem;
						height: 1.75rem;
						font-size: 1.75rem;
						line-height: 1;
						text-align: center;
						position: absolute;
						top: 50%;
						left: $base-margin;
						transform: translateX(0) translateY(-50%);
						color: $orange;
					}
				}
			}
		}
	}

	// ========================================
	// SM *MIN-WIDTH*
	// ========================================
	@include breakpoint(sm) {
		.row.top {
			padding-top: $base-margin/1.3;
			padding-bottom: $base-margin/1.3;
		}
	}//sm

	// ========================================
	// MD *MIN-WIDTH*
	// ========================================
	@include breakpoint(md) {

		.wrap {
			max-width: 500px;
		}

		#overlay-close {
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 500px;
			opacity: 0;
			z-index: 98;
		}

	}//md

	// ========================================
	// LG *MIN-WIDTH*
	// ========================================
	@include breakpoint(lg) {
	}//lg

}//header-popup

// ========================================
// FOOTER
// ========================================
footer#footer {
	background: $white;
	font-size: 16px;
	color: $grey-dark;

	a {color: $grey-dark;}

	.inside {
		padding-bottom: $base-margin*2;
		padding-top: $base-margin*2;
	}

	.logo {
		img {
			max-height: 4rem;
		}
	}

	p {
		margin-bottom: $base-margin/2;
		&:last-child {margin-bottom: 0;}
	}

	.footer-legal {
		display: none;
		background: $black;
		.inside {
			padding-bottom: 1rem;
			padding-top: 1rem;
			overflow: hidden;
		}
	}

	.menu-footer-menu-container {
		padding: 3% 0;
	}//menu-footer-menu-container

	// ========================================
	// SM *MIN-WIDTH*
	// ========================================
	@include breakpoint(sm) {
	}//sm

	// ========================================
	// MD *MIN-WIDTH*
	// ========================================
	@include breakpoint(md) {
	}//md

	// ========================================
	// LG *MIN-WIDTH*
	// ========================================
	@include breakpoint(lg) {
		p.copyright {
			br {
				display: none;
			}
		}
	}//lg

}

// ========================================
// PAGINATION
// ========================================
.pagination {

	h2 {display: none !important;}

	.nav-links {
		@extend .center-xs;
		margin: -0.313rem;
	}

	.page-numbers {
		@extend %btn;
		@extend %btn-sm;
		margin: 0.313rem;
		&.current {
			background: $grey-light;
			color: $black;
		}
		&.next {
		}
		&.prev {
		}
	}

}//pagination

// ========================================
// SIDEBAR MENU
// ========================================
.sidebar-menu {
	ul {
		@extend %list-block;
		line-height: $lh-compact;
		li {
			margin-bottom: 0.313rem;
			&:last-child {margin-bottom: 0;}
			&.current-menu-item {
				> a {
					background: $black;
					color: $white;
				}
			}
			a {
				padding: 1.25rem 1rem 0.950rem;
				background: $base-cta-background;
				color: $base-cta-text;
				text-decoration: none;
				display: block;
				text-transform: uppercase;
				font-weight: $bold;
				font-size: $header-h5-font-size;
				&:hover {
					background: $base-cta-hover-background;
					color: $base-cta-text;
					text-decoration: none;
				}
			}
			ul {
				li {
					margin-top: 0.313rem;
					a {
						text-transform: none;
						font-weight: $regular;
						padding-bottom: 0.750rem;
						padding-top: 0.750rem;
						font-size: inherit;
					}
					ul {
						li {
							border-left: 0.616rem solid darken($base-cta-hover-background,10%);
							a {
								margin-left: 0.313rem;
								font-size: 90%;
							}
						}
					}
				}
			}
		}
	}
}//sidebar-menu

// ========================================
// SEARCH OVERLAY
// ========================================
#search-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999;
	background: rgba($black,0.9);

	.row {
		min-height: 100vh;
	}

	.close-wrap {
		margin-top: $base-margin/2;
	}

	#search-close {
		display: inline-block;
		color: $white;
		font-size: 1.5rem;
		opacity: 0.75;
		cursor: pointer;
		&:before {
			font-size: 1.125rem;
			margin-right: 0.616rem;
		}
		&:hover {
			opacity: 1.0;
		}
	}

}//search-overlay

// ========================================
// SOCIAL MEDIA MENU
// ========================================
.menu-social-menu-container {
	ul.menu {
		@extend %list-inline;
		margin-left: -$base-margin/4;
		margin-right: -$base-margin/4;
		margin-bottom: $base-margin/2;
		li {
			margin-left: $base-margin/4;
			margin-right: $base-margin/4;
			margin-bottom: $base-margin/2;
			a {
				@extend .icon-before;
				width: 2.8rem;
				height: 2.8rem;
				display: block;
				overflow: hidden;
				text-indent: -9999px;
				background: $grey-light;
				color: $primary !important;
				text-decoration: none;
				position: relative;
				font-size: 1.2rem;
				text-align: center;
				line-height: 1;
				&:before {
					text-indent: 0;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateY(-50%) translateX(-50%);
				}
				&:hover {
					background: darken($grey-light,5%);
					text-decoration: none;
				}
			}
			&.facebook a {
				@extend .icon-facebook;
			}
			&.twitter a {
				@extend .icon-twitter;
			}
			&.linkedin a {
				@extend .icon-linkedin;
			}
			&.youtube a {
				@extend .icon-youtube;
			}
			&.instagram a {
				@extend .icon-instagram;
			}
		}
	}//ul.menu
}//menu-social-menu-container

// ========================================
// FOOTER MENU
// ========================================
.menu-footer-menu-container {
	ul.menu {
		@extend %list-inline;
		margin: 0 -0.313rem -0.313rem -0.313rem;
		li {
			margin: 0 0.313rem 0.313rem 0.313rem;
		}
	}//ul
}//menu-footer-menu-container

// ========================================
// BELLOWS - MENU
// ========================================
.bellows_navigation_widget-class {
	.bellows {
		border-top: 1px solid $grey-light !important;
		margin: 0 !important;
	}

	.fa-chevron-down {@extend .icon-down;}
	.fa-chevron-up {@extend .icon-up;}

	.bellows-target {
		background: lighten($black,25%) !important;
		color: $white !important;
		text-transform: uppercase !important;
		font-size: 1.750rem !important;
		font-weight: $bold !important;
		border-bottom: 1px solid $grey-light !important;
		line-height: 1 !important;
	}

	.bellows .bellows-nav .bellows-submenu .bellows-target {
		font-size: 18px !important;
		text-transform: none !important;
		background: $grey-light !important;
		color: $grey-dark !important;
		border-bottom-color: $grey-medium !important;
		padding: 1.125rem $base-margin 1rem $base-margin !important;
	}

	.bellows .bellows-nav .bellows-subtoggle {
		width: 5rem !important;
	}

	.bellows .bellows-nav .bellows-menu-item-has-children > .bellows-target {
		padding-right: 5rem !important;
	}

	.bellows .bellows-nav .bellows-subtoggle .fa {
		margin: 0 !important;
		transform: translateY(-50%) !important;
		text-align: center !important;
		font-size: 2rem !important;
	}
}
