// *****************************************
// TYPOGRAPHY
// *****************************************
@import url('https://fonts.googleapis.com/css?family=Montserrat:500,500i,700,700i|Oswald:200,300,400,600');

// =========================================
// DEFINED FONTS
// =========================================
$sans-serif:  'Montserrat', sans-serif; //400,700
$headline:  'Oswald', sans-serif; //300,400,600

// =========================================
// FONT WEIGHTS
// =========================================
$regular: 500;
$bold: 700;

// =========================================
// LINE HEIGHTS
// =========================================
$lh-base: 1.8;
$lh-compact: 1.2;

// =========================================
// BASE FONT STYLES + SIZE
// =========================================
$base-font-family: $sans-serif;
$base-font-weight: $regular;
$base-font-size: 16px;
$base-line-height: $lh-base;
$base-letter-space: normal;

// =========================================
// HEADING FONT SIZES
// =========================================
$header-h1-font-size: 2.625rem; //50px
$header-h2-font-size: 2.5rem;   //40px
$header-h3-font-size: 1.475rem; //30px
$header-h4-font-size: 1.5rem;   //24px
$header-h5-font-size: 1.375rem; //22px

// =========================================
// FRONT PAGE HEADING FONT SIZES
// =========================================
$front-header-h1-font-size: 4.4rem; //60px
$front-header-h2-font-size: 4rem;   //40px
$front-header-h3-font-size: 1.875rem; //30px
$front-header-h4-font-size: 1.5rem;   //24px

// =========================================
// HEADING FONT STYLES
// =========================================
$header-font-family: $headline;
$header-font-weight: $regular;
$header-line-height: $lh-base;
$header-letter-space: normal;
$header-case: none;

// =========================================
// CALL TO ACTION
// =========================================
$base-cta-font-size: 1.15rem;
$base-cta-font-weight: $bold;

// =========================================
// MISC FONT VARIABLES
// =========================================
$base-small-font-size: 14px;

// =========================================
// TYPE HTML TAGS
// =========================================
small,.small {
	font-size: $base-small-font-size;
}//small

p {
	@extend %block;
	&:last-child {margin-bottom: 0;}
	&.error { @extend %error-msg; }
} //p

b,bold,strong {
	font-weight: $bold;
}//bold

em,i,italic,italics {
	font-style: italic;
}//italic

u, underline {
	text-decoration: underline;
}

.caps {
	text-transform: uppercase;
}

hr {
	pointer-events: none;
	display: block;
	clear: both;
	width: 100%;
	margin: $base-margin 0;
	width: 100%;
	height: 0.313rem;
	border: none;
	background: $grey-light;
}

// =========================================
// CALL TO ACTION
// =========================================
a {
	position: relative;
	display: inline-block;
	color: $grey-dark;
	text-decoration: none;
	cursor: pointer !important;
	&:hover {
		color: $base-link-hover-color;
		text-decoration: none;
	} //hover
} //a

.btn {
	@extend %btn;

	&.sm {
		@extend %btn-sm;
	}

}//btn

// =========================================
// LISTS
// Dev: wrapped in .page to prevent edits to header & toolbar lists.
// =========================================
%checklist {
	padding-bottom: 30px;
	list-style: none;
	li {
		padding: 5px 0 5px 30px;
		font-family: $headline;
		font-weight: 300;
		font-size: 1.3em;
		letter-spacing: 1px;
		background: url(../blankslate-child/img/icon-check-red-01.png) no-repeat 0 8px;
		background-size: 20px auto;
		line-height: 1.4;
		ul { margin-top: 10px; }
		li {
			position: relative;
			padding-left: 20px;
			font-size: 1.3rem;
			list-style: none;
			background: none;
			line-height: 1.2;
			&:before {
				position: absolute;
				top: .5rem;
				left: 0;
				font-family: $sans-serif;
				font-size: 1.1rem;
				font-weight: 500;
				content: 'o';
			}//&:before
		}
		@include breakpoint(xsm) {

		}//xsm

		@include breakpoint(sm) {

		}//sm

		@include breakpoint(md) {
			padding: 10px 0 10px 40px;
			background-position: 0 13px;
			background-size: 25px auto;
			li:before { top: .7rem; }
		}//md

		@include breakpoint(mdlg) {

		}//mdlg

		@include breakpoint(lg) {

		}//lg

		@include breakpoint(xlg) {

		}//xlg`
	}//li
}//%checklist

%ul-zero {
	margin: 0;
	li {
		margin: 0;
		list-style: none;
		background: none;
		padding: 0;
	}//li
}//%ul-zero

.ul-zero { @extend %ul-zero; }

#container {
	ul {
		&:not(.ul-zero) { @extend %checklist; }
		&.ul-zero { @extend %ul-zero; }
	}
	ol {
		@extend %list-digit;
		&.ul-zero { @extend %ul-zero; }
	}
	ul.inline {@extend %list-inline;}

	ul,ol {
		&.two-col {
			@include breakpoint(md) {
				// column-count: 2;
				li {
					/* autoprefixer: off */
					page-break-inside: avoid;
				}
				li {
  				break-inside: avoid;
				}
			}//md
		}
	}

	p {
		& > a:not(.cta) {
			position: relative;
			font-weight: 700;
			text-transform: uppercase;
			transition: all 0.2s;
			&:after {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 4px;
				background: $grey-light;
				opacity: 1;
				content: '';
			}//after
			&:hover {
				&:after { opacity: 0.5; }
			}//hover
		}//& > a
	}//p

}//#container

.main {
	.h2,h2 {
		font-size: 2.8rem;
		font-weight: 300;
		text-transform: uppercase;
		line-height: 1.2;
	}//.h2,h2
	.h3,h3 {
		font-size: 2.3rem;
		font-weight: 200;
		color: $grey-medium;
		letter-spacing: 1px;
	}//.h3,h3
}//main

// =========================================
// HEADERS
// =========================================
.h1,h1 {font-size: $header-h1-font-size;}
@include breakpoint(sm) { .h1,h1 {font-size: $header-h1-font-size;} }//sm
.h2,h2 { font-size: $header-h2-font-size; }
.h3,h3 {font-size: $header-h3-font-size;}
.h4,h4 {font-size: $header-h4-font-size;}
.h5,h5 {font-size: $header-h5-font-size;}
.h6,h6 {font-size: $base-font-size;}

h1,h2,h3,h4,h5,h6 {
	@extend %block;
	@extend %header;
	font-weight: 400;
	&.end {margin-bottom: 0;}
}

h4,.h4 {
	a {
		color: $primary !important;
		&:hover { text-decoration: underline; }
	}
	@include breakpoint(xsm) {

	}//xsm

	@include breakpoint(sm) {

	}//sm

	@include breakpoint(md) {

	}//md

	@include breakpoint(mdlg) {

	}//mdlg

	@include breakpoint(lg) {

	}//lg

	@include breakpoint(xlg) {

	}//xlg
}//h4

.page-hero {
	h1,.h1 {
		font-size: $header-h1-font-size;
		font-weight: 400;
		text-transform: uppercase;
		line-height: 1.1 !important;
		@include breakpoint(xsm) {

		}//xsm

		@include breakpoint(sm) {

		}//sm

		@include breakpoint(md) {
			font-size: $header-h1-font-size*1.2;
		}//md

		@include breakpoint(mdlg) {
			font-size: $header-h1-font-size*1.4;
		}//mdlg

		@include breakpoint(lg) {
			// font-size: $header-h1-font-size*1.6;
		}//lg

		@include breakpoint(xlg) {

		}//xlg
	}//h1
	h2,.h2 {
		font-size: $header-h2-font-size;
		@include breakpoint(xsm) {

		}//xsm

		@include breakpoint(sm) {

		}//sm

		@include breakpoint(md) {

		}//md

		@include breakpoint(mdlg) {

		}//mdlg

		@include breakpoint(lg) {

		}//lg

		@include breakpoint(xlg) {

		}//xlg
	}//h2
	h3,.h3 {
		font-size: $header-h3-font-size;
		font-weight: 300;
		color: $grey-medium;
		@include breakpoint(xsm) {

		}//xsm

		@include breakpoint(sm) {

		}//sm

		@include breakpoint(md) {

		}//md

		@include breakpoint(mdlg) {
			font-size: $header-h3-font-size*1.2;
			font-weight: 200;
		}//mdlg

		@include breakpoint(lg) {

		}//lg

		@include breakpoint(xlg) {

		}//xlg
	}//h3
}//page-hero
