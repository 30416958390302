// *****************************************
// TEMPLATE PARTS
// views, modules, plugins, widgets
// *****************************************


// =========================================
// Widget - Social Media
// =========================================
.widget-social-media {
	clear: both;
	line-height: 1;
	ul.inline.social {
		margin-right: -$base-margin/4;
		margin-left: -$base-margin/4;
		li {
			margin-right: $base-margin/4;
			margin-left: $base-margin/4;
			margin-bottom: 0;
			a {
				display: block;
				position: relative;
				text-decoration: none;
				width: 50px;
				height: 50px;
				overflow: hidden;
				border-radius: 100%;
				background: $black;
				color: $white;
				line-height: 1;
				font-size: 24px;
				text-align: center;
				@extend %transition;
				&:hover {
					background: $black;
					color: $white;
				}
				&:before {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateX(-50%) translateY(-50%);
				}
			}
		}
	}
}//widget-social-media

// =========================================
// Hero
// =========================================
.page-hero {
	@extend %cover;
	clear: both;
	background: $grey-light;
	
	.row {
		padding-bottom: $base-margin*2;
		padding-top: $base-margin*2;
	}
	h1 {
		line-height: $lh-compact;
	}
	h2 {
		margin-bottom: 0;
		margin-top: $base-margin/2;
		line-height: $lh-compact;
		&:empty {display: none !important;}
	}
	// ============================================================
	//  50/50
	// ============================================================
	&.fifty-fifty {
		.col {
			&.col-left {
				padding: 8% $base-margin;
				background: $white;
			}//&.col-left
			&.col-right {
				min-height: 100px;
			}//&.col-right
		}//col
		// DARK HEADER ===========================
		&.dark {
			.inside { @extend %section-box-shadow; }
			.col {
				&.col-left {
					background: $grey-dark url(../blankslate-child/img/hero-bg-dark-arrows-01.png);
					h1,
					h2 { color: $white; }
				}//&.col-left
			}//col
		}//&.dark
	}//&.fifty-fifty
	// ============================================================
	//  FULL BACKGROUND
	// ============================================================
	.full-bg { padding: 10% 6%; }
	@include breakpoint(xsm) {

	}//xsm

	@include breakpoint(sm) {
		&.fifty-fifty {
			.inside {
				display: flex;
				justify-content: space-between;
				align-items: stretch;
			}//inside
			.col {
				flex: 0 0 50%;
				&.col-left {
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding: 10% 6%;
				}//&.col-left
			}//col
		}//&.fifty-fifty
	}//sm

	@include breakpoint(md) {
		// .col { min-height: 400px; }
	}//md

	@include breakpoint(mdlg) {
		// .col { min-height: 450px; }
	}//mdlg

	@include breakpoint(lg) {
		// .col { min-height: 520px; }
	}//lg

	@include breakpoint(xlg) {
		// .col { height: 640px; }
	}//xlg
}//page-hero

// =========================================
// ENTRY
// =========================================
.entry {
	clear: both;

	.row {
		margin-bottom: -$base-margin/2;
		&.nested {margin-bottom: 0;}
	}

	.col {
		margin-bottom: $base-margin/2;
	}

	.col-title {
		h2 {
			margin-bottom: 0;
			line-height: $lh-compact;
		}
	}

	.col-date-author {
		margin-bottom: 0;
		.col-sub-item {
			display: inline-block;
			margin-right: $base-margin/2;
			margin-bottom: $base-margin/2;
		}
	}

	p {
		margin-bottom: $base-margin/2;
		&:last-child {margin-bottom: 0;}
	}

}//entry

// =========================================
// ENTRY - TEAM
// =========================================
.entry.type-team {
	clear: none;

	.image {
		a {display: block;}
		img {border: 1px solid $grey-light;}
	}

	.title {
		margin-bottom: 0.616rem;
		h3 {
			margin-bottom: 0;
			line-height: $lh-compact;
			font-weight: $regular;
		}
	}

	.job_title {
		font-size: 0.950em;
		color: $grey-dark;
	}

}//entry.type-yeam

// =========================================
// ENTRY - FAQ
// =========================================
.entry.type-faq {
	margin-bottom: $base-margin;
	background: $grey-light;
	padding: $base-margin;
	&.open {
		background: $base-cta-background;
		color: $base-cta-text;
		i {
			transform: rotate(180deg) translateY(50%);
		}
		#question {
			a {
				text-decoration: underline;
				color: $base-cta-text;
			}
		}
	}

	span.qa {
		display: inline-block;
		min-width: 50px;
		line-height: $lh-compact;
		font-weight: $bold;
	}

	.answer-text {
		display: inline-block;
	}

	i {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		line-height: 1;
		font-size: 1.5rem;
		color: #000;
		opacity: 0.3;
		@extend %transition;
	}

	#question {
		line-height: $lh-compact;
		position: relative;
		a {
			display: inline-block;
			color: $black;
			text-transform: uppercase;
			font-weight: $bold;
		}
	}

	#answer {
		display: none;
		margin-top: $base-margin/2;
	}

}//entry.type-faq

// =========================================
// ENTRY - TESTIMONIALS
// =========================================
.entry.type-testimonials {

	.content {
		margin-bottom: $base-margin/2;
	}

}

// =========================================
// ENTRY - CAREERS
// =========================================
.entry.type-careers {
	.content {
		margin-bottom: $base-margin/2;
	}
}

// =========================================
// ENTRY - GALLERY
// =========================================
.entry.image_gallery {
}

// =========================================
// SINGLE - TEAM - ENTRY
// =========================================
.single-team-entry {

	.image {
		img {
			border: 1px solid $grey-light;
		}
	}

	.icon {
		&:before {
			width: 2.50rem;
			height: 2.50rem;
			text-align: center;
			font-size: 1.25rem;
			margin-right: 0.616rem;
			border-radius: 100%;
			background: $base-cta-background;
			color: $base-cta-text;
			line-height: 2.50rem;
			text-decoration: none;
		}
		&:hover {
			&:before {
				background: $base-cta-hover-background;
			}
		}
	}

	.title {
		text-transform: uppercase;
		line-height: $lh-compact;
		margin-bottom: 0.313rem;
	}

	.job_title {
		color: $grey-dark;
		font-size: 0.950em;
	}

	.mb {
		margin-bottom: 0.616rem;
		word-break: break-all;
		word-wrap: break-word;
		&:last-child {margin-bottom: 0;}
	}


}//single_team_entry

// ==================================
// GOOGLE MAP
// ==================================
.google-map {
	clear: both;
	iframe {
		display: block !important;
		border: 1px solid $grey-light !important;
		width: 100% !important;
		height: 75vw !important;
		max-height: 300px !important;
		min-height: 200px !important;
	}
}

// ==================================
// SITE MAP
// ==================================
.site-map {

	.menu-item-34,
	.menu-item-36,
	.menu-item-42 {
		display: none;
	}

	ul {
		margin-bottom: 0;
	}

}

// ==================================
// BLOG CATEGORIES LIST
// ==================================
ul.blog-categories {
	li {
		border-bottom: 2px solid $grey-light;
		margin-bottom: $base-margin/2;
		padding-bottom: $base-margin/2;
		&:last-child {
			border-bottom: none;
			margin-bottom: 0;
			padding-bottom: 0;
		}
		a {
			display: inline-block;
			font-size: $header-h4-font-size;
		}
	}
}
