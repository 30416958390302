// *****************************************
// MIXINS
// *****************************************
@mixin overlay($opacity:0.3,$bgcolor:$black) {
	position: relative;
	&:before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $bgcolor;
		opacity: $opacity;
		content: '';
		@include breakpoint(sm) {
			// opacity: 0;
		}//sm
	}//&:before
}//@mixin overlay

// ============================================================
//  PADDING AND MARGINS
// ============================================================
@mixin plr($pl:$base-margin,$pr:$base-margin) {
	padding-left: $pl;
	padding-right: $pr;
}//@mixin plr()

@mixin ptb($pt:$base-margin,$pb:$base-margin) {
	padding-top: $pt;
	padding-bottom: $pb;
}//@mixin ptb()

@mixin mlr($ml:$base-margin,$mr:$base-margin) {
	margin-left: $ml;
	margin-right: $mr;
}//@mixin mlr()

@mixin mtb($mt:$base-margin,$mb:$base-margin) {
	margin-top: $mt;
	margin-bottom: $mb;
}//@mixin mtb()

// ============================================================
//  POSITION ABSOLUTE
// ============================================================
@mixin posab($top:0,$left:0) {
	position: absolute;
	top: $top;
	left: $left;
	width: 100%;
	height: 100%;
}//@mixin posab

// =========================================
// LIST
// =========================================
@mixin list($type) {
	clear: both;
	margin-bottom: $base-margin;

	@if $type == check {
		li {
			list-style-type: none;
			@extend %list-item;
			li {
				list-style-type: decimal;
			}
		}
	}

	@if $type == bullet {
		li {
			list-style-type: disc;
			@extend %list-item;
			li {
				list-style-type: circle;
			}
		}
	}

	@if $type == digit {
		li {
			list-style-type: decimal;
			@extend %list-item;
			li {list-style-type: lower-alpha;}
		}
	}

	@else if $type == inline {
		li {
			display: inline-block;
			position: relative;
			list-style-type: none;
			margin-left: 0;
			margin-right: $base-margin;
		}
	}

	@else if $type == block {
		margin: 0;
		padding: 0;
		display: block;
		clear: both;
		width: auto;
		list-style-type: none;
		li {
			list-style-type: none;
			display: block;
			clear: both;
			margin: 0;
			padding: 0;
			width: auto;
			ul {
				margin: 0;
				padding: 0;
				li {
					margin: 0;
					padding: 0;
				}
			}
		}
	}

}

// =========================================
// INSIDE WRAP
// =========================================
@mixin inside($display:block) {
	max-width: $viewport-xlg;
	width: auto;
	position: relative;
	display: block;
	margin-left: auto;
	margin-right: auto;

	@if $display == "flex" {display: flex;}

}//mixin: inside

// =========================================
// TRANSITION
// =========================================
@mixin transition($length:0.3s) {
	transition-property: all;
	transition-timing-function: ease-in-out;
	transition-duration: $length;
}//mixin: transition

// =========================================
// BUTTONS
// =========================================
@mixin button($size:med) {
	display: inline-block;
	cursor: pointer;
	border: none;
	outline: none;
	appearance: none;
	text-align: center;
	text-decoration: none;
	font-size: $base-cta-font-size;
	font-weight: $base-cta-font-weight;
	text-transform: uppercase;
	line-height: 1;
	letter-spacing: $header-letter-space;
	padding: 1.125rem $base-margin 1.063rem $base-margin;
	@extend %transition;
	&.icon {
		&:before {
			margin-right: 0.616rem;
		}
	}
	&:hover {
		text-decoration: none;
	}
}//mixin: button

// =========================================
// IMAGES
// =========================================
@mixin img($style:Inline) {
	border: 0;
	outline: none;
	height: auto;
	@if $style == 'Inline' {
		display: inline;
		width: auto;
		max-width: 100%;
	}//inline
	@else if $style == 'Block'{
		display: block;
		width: 100%;
		max-width: none;
	}//block
}//images
