// *****************************************
// VARIABLES
// color and typography variables are stored in there respective files
// *****************************************


// =========================================
// MARGINS
// =========================================
$base-margin: 1.563rem; //25px
$base-margin-row: 6.250rem; //100px