// *****************************************
// BASE
// site-wide constructed code
// *****************************************


// =========================================
// HTML, BODY
// =========================================
html,
body {
	font-family: $base-font-family;
	font-size: $base-font-size;
	font-weight: $base-font-weight;
	background-color: $base-background-color;
	color: $base-text-color;
	letter-spacing: $base-letter-space;
	line-height: $base-line-height;
	min-height: 100vh;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-size-adjust: none;
}
body {
	background: url(../blankslate-child/img/bg-noise-01.png);
	&.menu-open {
		max-height: 100vh;
		overflow: hidden;
	}
}
html {
	font-size: 75%;
}//html,body

// ========================================
// XS *MIN-WIDTH*
// ========================================
@include breakpoint(xs) {
	body {font-size: 17px;}
	html {font-size: 80%;}
}//xs

// ========================================
// SM *MIN-WIDTH*
// ========================================
@include breakpoint(sm) {
	body {font-size: 18px;}
	html {font-size: 85%;}
}//sm

// ========================================
// MD *MIN-WIDTH*
// ========================================
@include breakpoint(md) {
	// body {font-size: 19px;}
	html {font-size: 90%;}
}//md

// ========================================
// LG *MIN-WIDTH*
// ========================================
@include breakpoint(lg) {
	// body {font-size: 20px;}
	html {font-size: 100%;}
}//lg
