/*
Theme Name: Straight North
Description: Blankslate Child Theme
Author: Straight North
Author URI: http://www.straightnorth.com
Template: blankslate
Version: 1.0.0
License:
License URI:
Tags:
Text Domain: blankslate-child
*/


// DEVELOPED BY STRAIGHTNORTH.COM
// ==============================
// ==============================

@import url("https://use.typekit.net/xip0hbu.css"); //typkit

@import "_reset.scss"; //reset scss

@import "_viewports.scss"; //responsive viewports

//@import "_animate.scss"; //animate.css

@import "_colorbox.scss"; //colorbox

@import "_variables"; //variables

@import "_colors.scss"; //colors

@import "_glyphs.scss"; //glyphs

@import "_typography.scss"; //fonts, type sizes

@import "_helpers.scss"; //mixins

@import "_mixins.scss"; //mixins

@import "_ctas.scss"; //ctas

@import "_extends.scss"; //extends

@import "_media.scss"; //images, videos, etc

@import "_flexboxgrid.scss"; //flexbox grid

@import "_base.scss"; // global styles

@import "_forms.scss"; // form styles

@import "_navigation.scss"; //header,footer, side nav, etc

@import "_layout.scss"; //layouts

@import "_frontpage.scss"; //front page styles

@import "_internal.scss"; //internal page styles

@import "_templateparts.scss"; //views, modules, etc

@import "_jquery-ui.scss"; //jquery ui used for date-picker. If no date field needed disable me and script in header.
