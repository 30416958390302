// =========================================
// IMAGES
// =========================================
svg,
img {
	image-rendering: -webkit-optimize-contrast;
	@include img(Inline);
} //img inline

img.block {@extend %block-image;} //img block

.no-touch {pointer-events: none;} //disable pointer events