/* Generated by Glyphter (http://www.glyphter.com) on  Tue May 15 2018*/

@font-face {
    font-family: 'Straight North Base';
    src: url('fonts/Straight-North-Base.eot');
    src: url('fonts/Straight-North-Base.eot?#iefix') format('embedded-opentype'),
         url('fonts/Straight-North-Base.woff') format('woff'),
         url('fonts/Straight-North-Base.ttf') format('truetype'),
         url('fonts/Straight-North-Base.svg#Straight-North-Base') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon-after:after,
.icon-before:before,
[class*='icon-']:before{
	display: inline-block;
   font-family: 'Straight North Base';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}

// NAVIGATION
.icon-menu:before{content:'\0041';}
.icon-gps:before{content:'\0042';}
.icon-close:before{content:'\0043';}
.icon-search:before{content:'\004e';}

// COMMUNICATIONS
.icon-phone:before{content:'\0045';}
.icon-facebook:before{content:'\0046';}
.icon-twitter:before{content:'\0047';}
.icon-linkedin:before{content:'\0048';}
.icon-youtube:before{content:'\0049';}
.icon-instagram:before{content:'\004b';}
.icon-mail:before{content:'\004d';}

// ARROWS
.icon-up:before{content:'\004c';}
.icon-down:before{content:'\0044';}
.icon-right:before{content:'\004f';}
.icon-left:before{content:'\0050';}