// ============================================================
//  COLORBOX OVERRIDES
// ============================================================
#colorbox {
  #cboxPrevious,
  #cboxNext { outline: none; }
  #cboxCurrent,
  #cboxTitle {
    bottom: 0;
    font-family: $headline;
    font-size: 0.9rem;
    font-weight: 300;
    color: $grey-dark;
    letter-spacing: 1px;
  }//#cboxTitle
  #cboxCurrent {
    display: none !important;
    @include breakpoint(xlg) {
	  	display: inline-block !important;
   	}///
  }
}//#colorbox

.bgimg {
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  background-size: cover !important;
}//bg-img

.mb3 { margin-bottom: $base-margin-row/3; }

.shadow { @extend %section-box-shadow; }

.narrow {
  max-width: 800px;
  margin: auto;
}
