// *****************************************
// LAYOUTS
// global layout code
// *****************************************


// ==================================
// ASIDE
// ==================================
aside {
	.widget {
		margin-bottom: $base-margin;
		&:last-child {margin-bottom: 0;}
	}
}//aside


// =========================================
// CONTAINERS / FRAMEWORK
// =========================================
#container {
	clear: both;
	position: relative;
	padding-top: calc( 6% + 79px );
	overflow: hidden;
	min-height: 300px;
	.home & {	padding-top: 60px; }
	@include breakpoint(lg) {
		padding-top: calc( 133px + 3% );
		.home & {	padding-top: 120px; }
	}//lg
}//container

.inside {
	@include inside;
}//inside

.main {
	@extend %vert-padding-sm;
}

%inner-narrow {
	max-width: 840px;
	margin: auto;
}//%inner-narrow

.inner-narrow { @extend %inner-narrow; }

// ==================================
// MARGIN CLASSES
// ==================================
.mb0 { margin-bottom: 0 !important; }
.mb4 { margin-bottom: $base-margin/4; }
.mb2 { margin-bottom: $base-margin/2; }
.mb { margin-bottom: $base-margin; }
.mb-row {margin-bottom: $base-margin-row;}


// =========================================
// IPHONE X WRAP
// =========================================
.wrap-x {
	width: 100%;
	&.pl {padding-left: env(safe-area-inset-left);}
	&.pr {padding-right: env(safe-area-inset-right);}
	&.pt {padding-top: env(safe-area-inset-top);}
	&.pb {padding-bottom: env(safe-area-inset-bottom);}
}//wrap-x
