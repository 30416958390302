// *****************************************
// FRONT PAGE
// welcome home
// *****************************************

body:not(.home) {
	.home-only {display: none !important;}
}

// ========================================
// FRONT PAGE ROW
// ========================================
.front-page-row {
	clear: both;
	width: 100%;
	padding-bottom: $base-margin-row/2;
	padding-top: $base-margin-row/2;
	position: relative;

	h1,.h1 {
		font-size: $front-header-h1-font-size/1.3;
		line-height: 1.1;
		.sm {
			margin-bottom: 10px;
			font-size: 2rem;
			font-weight: 300;
			letter-spacing: 1px;
		}
		.lg {
			text-transform: uppercase;
		}

		@include breakpoint(md) {
			font-size: $front-header-h1-font-size;
			.sm { letter-spacing: 2px; }
		}//md
	}

	h2,.h2 {
		font-size: $front-header-h2-font-size/1.5;
		line-height: 1.1;
		font-weight: 400;
		text-transform: uppercase;
		color: $white;
		@include breakpoint(md) {
			font-size: $front-header-h2-font-size/1.3;
		}//md
	}

	h3,.h3 {
		font-size: $front-header-h3-font-size;
		line-height: $lh-compact;
	}

	h4,.h4 {
		font-size: $front-header-h4-font-size;
		line-height: $lh-compact;
	}

}//front-page-row

.home {
	// ============================================================
	//  DEFAULTS
	// ============================================================
	.front-page-row {
		position: relative;
		z-index: 300;
		&:first-child {
			padding-top: 6.25rem;
			&:before { top: 3%; }
		}//&:first-child
		&:before {
			position: absolute;
			top: 0;
			right: 3%;
			display: block;
			width: 250px;
			height: 250px;
			background: url(../blankslate-child/img/bg-arrows-01.png) no-repeat 50% 50%;
			background-size: cover;
			content: '';
		}//&:before
	}//front-page-row
	// ============================================================
	//  HERO, HERO2
	// ============================================================
	.hero,
	.hero2 {
		overflow: hidden;
		.hero-top {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 8%;
			text-align: right;
			color: $white;
			@include overlay;
		}//hero-top
		nav.sec-nav {
			background: $white;
			@extend %section-box-shadow;
			ul {
				font-size: 0;
				text-align: center;
				li {
					display: inline-block;
					width: 50%;
					a {
						display: block;
						padding: 10% 5% 5%;
						font-family: $headline;
						font-size: 1.3rem;
						font-weight: normal;
						font-weight: 400;
						text-transform: uppercase;
						color: $primary;
						letter-spacing: 2px;
						line-height: 1;
						text-decoration: none !important;
						transition: all 0.4s;
						&:after {
							display: block;
							position: relative;
							width: 30px;
							height: 3px;
							left: 50%;
							margin: 4px 0 0 -15px;
							content: '';
							background: lighten($grey-light,5%);
						}
						&:hover {
							color: $grey-dark;
							background: lighten($grey-light,5%);
						}
					}//a
				}//li
			}//ul
		}//nav.sec-nav

		@include breakpoint(xsm) {

		}//xsm

		@include breakpoint(sm) {
			.hero-top:before { display: none; }
			nav.sec-nav {
				ul {
					li { width: 33.3333%;	}
				}//ul
			}//nav.sec-nav
		}//sm

		@include breakpoint(md) {
			.hero-top {	height: 400px; }
			nav.sec-nav {
				ul {
					text-align: left;
					li {
						width: 25%;
						text-align: center;
						a {
							padding: 15% 5% 12%;
							&:after {
								width: 45px;
								height: 5px;
								margin: 10px 0 0 -23px;
							}
						}
					}//li
				}//ul
			}//nav.sec-nav
		}//md

		@include breakpoint(mdlg) {
			.hero-top {	height: 480px; }
		}//mdlg

		@include breakpoint(lg) {
			.hero-top {	height: 580px; }
			nav.sec-nav {
				ul {
					text-align: left;
					li {
						width: 22%;
						a { padding: 40px 0 30px; }
					}
				}//ul
			}//nav.sec-nav
		}//lg

		@include breakpoint(xlg) {

		}//xlg
	}//hero. hero2
	// ============================================================
	//  HERO2
	// ============================================================
	.hero2 {
		&:before {
			right: auto;
			left: 4%;
		}//&:before
		@include breakpoint(xsm) {

		}//xsm

		@include breakpoint(sm) {

		}//sm

		@include breakpoint(md) {

		}//md

		@include breakpoint(mdlg) {

		}//mdlg

		@include breakpoint(lg) {
			nav.sec-nav {
				ul {
					li { width: 15%; }
				}//ul
			}//nav.sec-nav
		}//lg

		@include breakpoint(xlg) {

		}//xlg
	}//hero2
	// ============================================================
	//  CRED
	// ============================================================
	.cred {
		padding-bottom: 4.6rem;
		&:before { display: none; }
		.outer-wrap {
			position: relative;
			background: $white;
			@extend %section-box-shadow;
		}//outer-wrap
		h2 {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			top: -2.4rem;
			padding: 30px;
			font-size: 2rem;
			font-weight: 300;
			text-transform: none;
			letter-spacing: 2px;
			line-height: 1.3;
			color: $grey-dark;
			background: $grey-xlight;
		}//h2
		.cred-logos {
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex: 0 0 75%;
			ul {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
			}
			li {
				flex: 0 0 30%;
				padding: 15px;
			}
		}//ul.cred-logos
		@include breakpoint(xsm) {
			.cred-logos {
				li { flex: 0 0 20%;	}
			}//ul.cred-logos
		}//xsm

		@include breakpoint(sm) {

		}//sm

		@include breakpoint(md) {
			.col {
				display: flex;
				margin-bottom: 0;
				// ie10,11 =========================================
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					flex-direction: column;
				}// ie10,11
			}
			.col-inner {
				display: flex;
				justify-content: space-between;
				align-items: stretch;
				margin-bottom: 0;
			}//col
			h2 {
				flex: 0 0 25%;
				max-width: 25%;
				height: calc(100% + 4.8rem);
				padding: 40px;
				font-size: 2.4rem;
				// ie10,11 =========================================
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					span {
						display: block;
						max-width: 100%;
					}
				}// ie10,11
			}//h2
			.cred-logos {
				flex: 0 0 70%;
				ul {
					display: flex;
					justify-content: space-between;
					li { flex: 0 0 17%; }
				}
			}
		}//md

		@include breakpoint(mdlg) {

		}//mdlg

		@include breakpoint(lg) {

		}//lg

		@include breakpoint(xlg) {

		}//xlg
	}//cred
	// ============================================================
	//  ELEVATE
	// ============================================================
	.elevate {
		.elevate-top {
			height: 200px;
			padding: 8%;
			@include overlay;
		}//elevate-top
		.elevate-bottom {
			padding: 8%;
			background: $white url(../blankslate-child/img/elevate-map-01.png) no-repeat 90% 50%;
			background-size: auto 80%;
			@extend %section-box-shadow;
			p {	max-width: 640px;	}
			p.gsans { font-size: 1.1rem; }
		}//elevate-bottom

		@include breakpoint(xsm) {

		}//sm

		@include breakpoint(sm) {
			.elevate-top { height: 260px;	}
		}//sm

		@include breakpoint(md) {
			.elevate-top { height: 360px;	}
		}//md

		@include breakpoint(mdlg) {

		}//mdlg

		@include breakpoint(lg) {
			.elevate-top {
				height: 500px;
				&:before { display: none; }
			}//elevate-top
		}//lg

		@include breakpoint(xlg) {
			.elevate-top { height: 580px; }
		}//xlg
	}//elevate
	// ============================================================
	//  EXPERTISE
	// ============================================================
	.expertise {
		&:before {
			right: auto;
			left: 4%;
		}//&:before
		.expertise-top {
			padding: 8%;
			@include overlay(0.6);
			.top-left {
				max-width: 500px;
				p { color: $white; }
			}//top-left
			.top-right { display: none; }
		}//expertise-top
		.expertise-bottom {
			padding: 8%;
			background: $grey-xlight;
			@extend %section-box-shadow;
			li { display: inline-block; }
		}//expertise-bottom
		@include breakpoint(xsm) {

		}//xsm

		@include breakpoint(sm) {

		}//sm

		@include breakpoint(md) {
			.expertise-bottom {
				padding: 6% 8%;
				ul {
					column-count: 2;
					column-gap: 30px;
				}
			}//expertise-bottom
		}//md

		@include breakpoint(mdlg) {

		}//mdlg

		@include breakpoint(lg) {
			.expertise-top {
				display: flex;
				justify-content: space-between;
				background: $white;
				padding: 0 30px 0 0;
				@include overlay(1,$white);
				.top-left {
					flex: 0 0 40%;
					max-width: 40%;
					padding: 8%;
					h2,
					p { color: $grey-dark; }
				}//top-left
				.top-right {
					position: relative;
					top: -30px;
					display: block;
					flex: 0 0 60%;
					max-width: 60%;
				}//top-right
			}//expertise-top
			.expertise-bottom {
				padding: 8% 8% 6%;
				ul { column-count: 3; }
			}//expertise-bottom
		}//lg

		@include breakpoint(xlg) {
			.expertise-top {
				.top-right {
					top: -30px;
					margin-bottom: -90px;
				}//top-right
			}//expertise-top
		}//xlg
	}//expertise
	// ============================================================
	//  ABOUT
	// ============================================================
	.about {
		padding-bottom: 6.25rem;
		.col-inner {
			position: relative;
			@extend %section-box-shadow;
			.sec-left {
				position: absolute;
				top: 0;
				left:0;
				width: 100%;
				height: 100%;
			}//sec-left
			.sec-right {
				padding: 8%;
				@include overlay(0.65,$black);
				p { color: $white; }
			}//sec-right
		}//col-inner

		@include breakpoint(lg) {
			.col-inner {
				display: flex;
				justify-content: space-between;
				align-items: stretch;
				.sec-left {
					flex: 0 0 40%;
					max-width: 40%;
					position: relative;
					height: auto;
				}//sec-left
				.sec-right {
					flex: 0 0 60%;
					max-width: 60%;
					@include overlay(1,$white);
					h2,
					p { color: $grey-dark; }
				}//sec-right
			}//col-inner
		}//lg

		@include breakpoint(xlg) {
			.col-inner {
				.sec-left,
				.sec-right {
					flex: 0 0 50%;
					max-width: 50%;
				}
			}//col-inner
		}//xlg
	}//about
}//home
