// *****************************************
// FORMS
// *****************************************

// ==================================
// FORM
// ==================================
#searchform,
.wpcf7-form {
	display: block;

	label {
		display: block;
		clear: both;
		font-weight: $bold;
		text-transform: uppercase;
		.req {color: $red;}
	}

}

// ==================================
// TEXT FIELD
// ==================================
input[type=text],
.wpcf7-text {
	@extend %base-form;
	appearance: none;
}

// ==================================
// SELECT
// ==================================
.wpcf7-select {
	@extend %base-form;
	cursor: pointer;
	//hide annoyning outline in firefox
	&::-ms-expand { display: none; }
	&:-moz-focusring {
			color: transparent;
			text-shadow: 0 0 0 #000;
	}
}

// ==================================
// FANCY SELECT
// ==================================
.fancy-select {
	.wpcf7-form-control-wrap {
		@extend .icon-before;
		@extend .icon-down;
		position: relative;
		display: block;
		clear: both;
		z-index: 2;
		&:before {
			position: absolute;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			line-height: 1;
			top: 2px;
			right: 2px;
			bottom: 2px;
			width: 3.125rem;
			background: $black;
			z-index: 10;
			color: $white;
			font-size: 1.563rem;
			pointer-events: none;
		}
		.wpcf7-select {
			padding-right: 3.125rem;
			appearance: none;
		}
	}
}

// ==================================
// NUMBER FIELD
// ==================================
.wpcf7-number {
	@extend %base-form;
}

// ==================================
// TEXT AREA
// ==================================
.wpcf7-textarea {
	@extend %base-form;
	height: 9.375rem; //150px
	padding-bottom: 1rem;
	padding-top: 1rem;
	appearance: none;
}

// ==================================
// RADIO / CHECK BUTTON WRAPS
// ==================================
.wpcf7-radio,
.wpcf7-checkbox {
	@extend .row;
	@extend .nested;
	@extend .middle-xs;
}

// ==================================
// RADIO / CHECK BUTTONS
// ==================================
.wpcf7-list-item {
	@extend .col-xs-12;
	@extend .col-sm-4;
	@extend .col-md-3;
	@extend .col-lg-3;
	display: block !important;
	margin: 0 !important;
	line-height: 1;

	label {
		cursor: pointer;
		text-transform: none;
		font-weight: $regular;
	}

	input[type="radio"],
	input[type="checkbox"] {
		margin: auto;
		width: 16px;
		height: 16px;
		cursor: pointer;
		margin-right: 5px;
	}

}

// ==================================
// DATE
// ==================================
.wpcf7-date {
	@extend %base-form;
	cursor: pointer;
	appearance: none;

	&::-ms-clear { display: none; }

	&::-webkit-clear-button { display: none;}

	&::-webkit-inner-spin-button { display: none; }

	&::-webkit-calendar-picker-indicator {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		// purposly hidden and covering the entire field
		// to make the entire input box clickable in webkit.
	}

}

// ==================================
// QUIZ
// ==================================
.wpcf7-quiz {
	@extend %base-form;
}

// ==================================
// FILE
// ==================================
.wpcf7-file {
	@extend %base-form;
	height: auto;
	padding-bottom: 1rem;
	padding-top: 1rem;
}

// ==================================
// ERROR
// ==================================
.wpcf7-not-valid-tip {
	margin: -1px 0 0 0 !important;
	@extend %error-msg;
}
.wpcf7-not-valid {
	border-color: $red !important;
}

// ==================================
// UNWANTED CONTACT FORM 7 STUFF
// ==================================
.wpcf7-form .ajax-loader,
.wpcf7-response-output {display: none !important;}

// ========================================
// SEARCH INPUT FORM
// ========================================
#searchform {
	@extend .icon-before;
	@extend .icon-search;
	position: relative;
	padding-right: 3.750rem;
	&:before {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		z-index: 99;
		width: 3.125rem;
		text-align: center;
		color: $white;
		line-height: 1;
		font-size: 1.75rem;
		pointer-events: none;
	}

	label {display: none;}

	input[type=text] {
		margin: 0;
	}

	#searchsubmit {
		@extend .btn;
		position: absolute;
		top: 0;
		right: 0;
		width: 3.125rem;
		height: 3.125rem;
		overflow: hidden;
		padding: 0;
		text-indent: -9999px;
	}
}//search-form

// SEARCH PAGE FORM WRAP
.search-page-form {
	margin-bottom: $base-margin;
	padding-bottom: $base-margin;
	border-bottom: 0.313rem solid $grey-light;
}
