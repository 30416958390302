// *****************************************
// COLORS
// defined site colors
// *****************************************


// ==================================
// COLOR VARIABLES
// ==================================
$black: #000;
$white: #FFF;

$grey-xlight: #f5f5f5;
$grey-light: #e6e6e6;
$grey-medium: #888;
$grey-dark: #212121;

$green: #c9f43b;
$orange: #fb8319;
$red: #ed1c24;

$primary: $red;


// ==================================
// BASE COLORS
// ==================================
$base-background-color: $white;
$base-text-color: $black;
$base-link-color: $grey-dark;
$base-link-hover-color: $black;
$base-headline-color: $black;
$base-cta-background: $primary;
$base-cta-hover-background: darken($primary,10%);
$base-cta-text: $white;

// ==================================
// SELECTION / HIGHLIGHTS
// ==================================
::selection {
	background: $black;
	color: $white;
}

// ==================================
// CLASSES
// ==================================
.black {color: $black;}
.white {color: $white;}
.grey-light {color: $grey-light;}
.grey-medium {color: $grey-medium;}
.grey-dark {color: $grey-dark;}
.orange {color: $orange;}

.bg-black {background-color: $black;}
.bg-white {background-color: $white;}
.bg-grey-light {background-color: $grey-dark;}
.bg-grey-medium {background-color: $grey-dark;}
.bg-grey-dark {background-color: $grey-dark;}
.bg-orange {background-color: $orange;}
