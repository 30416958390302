// *****************************************
// VIEWPORTS
// *****************************************
$viewport-xsm: 480px;   // Phone 480px - 0px
$viewport-sm: 667px;   // Phone 667px - 0px
$viewport-md: 769px;  // Tablet 768px - 414px
$viewport-mdlg: 890px;  // Tablet 768px - 414px
$viewport-lg: 1024px;  // Desktop 1024px - 100%
$viewport-xlg: 1280px;  // Desktop 1280px - 100%

// =========================================
// BREAKPOINT
// =========================================
@mixin breakpoint($point) {
  @if $point == xsm {
    @media (min-width: $viewport-xsm) { @content; }
  }
  @if $point == sm {
    @media (min-width: $viewport-sm) { @content; }
  }
  @else if $point == md {
    @media (min-width: $viewport-md) { @content; }
  }
  @else if $point == mdlg {
    @media (min-width: $viewport-mdlg) { @content; }
  }
  @else if $point == lg {
    @media (min-width: $viewport-lg) { @content; }
  }
  @else if $point == xlg {
    @media (min-width: $viewport-xlg) { @content; }
  }
}//mixin: breakpoint

/*
@include breakpoint(xsm) {

}//xsm

@include breakpoint(sm) {

}//sm

@include breakpoint(md) {

}//md

@include breakpoint(mdlg) {

}//mdlg

@include breakpoint(lg) {

}//lg

@include breakpoint(xlg) {

}//xlg
*/
// ========================================
// SM *MIN-WIDTH*
// ========================================
@include breakpoint(sm) {
}//sm

// ========================================
// MD *MIN-WIDTH*
// ========================================
@include breakpoint(md) {
}//md

// ========================================
// LG *MIN-WIDTH*
// ========================================
@include breakpoint(lg) {
}//lg
