.cta {
  display: inline-block;
  font-family: $headline;
  font-size: 1.6rem;
  font-weight: 400;
  color: $primary;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-decoration: none !important;
  transition: all 0.3s;
  &.sm { font-size: 1.2rem; }
  &:after {
    position: relative;
    display: block;
    width: 40px;
    height: 4px;
    left: 50%;
    margin-left: -20px;
    background: $grey-medium;
    content: '';
    opacity: 0.15;
    transition: all 0.3s;
  }//&:after
  &:hover {
    color: lighten($primary,10%);
    &:after { opacity: 0.5; }
  }//&:hover
}//cta
