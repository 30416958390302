// *****************************************
// EXTENDS
// reusable code stored here
// *****************************************

%fluid-img {
	display: block;
	max-width: 100%;
	height: auto;
}//%fluid-img

.fluid-img { @extend %fluid-img; }

// ==================================
// SIDE NAV MENU
// ==================================
%menu-side {
}

// =================================
//  SECTION BOX SHADOW
// =================================
%section-box-shadow {
	box-shadow: 0 20px 30px rgba($black,.06);
}//%section-box-shadow

// ==================================
// ERROR MSG
// ==================================
%error-msg {
	display: block !important;
	background: $red !important;
	color: $white !important;
	line-height: $lh-compact !important;
	padding: 1rem !important;
	font-weight: $bold !important;
	font-size: 16px !important;
	margin: 0 !important;
}

// ==================================
// BACKGROUND COVER
// ==================================
%cover {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
}

// ==================================
// VERT PADDING
// ==================================
%vert-padding {
	padding-bottom: $base-margin;
	padding-top: $base-margin;

	// ========================================
	// SM *MIN-WIDTH*
	// ========================================
	@include breakpoint(sm) {
	}//sm

	// ========================================
	// MD *MIN-WIDTH*
	// ========================================
	@include breakpoint(md) {
		padding-bottom: $base-margin-row;
		padding-top: $base-margin-row;
	}//md

	// ========================================
	// LG *MIN-WIDTH*
	// ========================================
	@include breakpoint(lg) {
	}//lg

}

%vert-padding-sm {
	padding-bottom: $base-margin;
	padding-top: $base-margin;

	// ========================================
	// SM *MIN-WIDTH*
	// ========================================
	@include breakpoint(sm) {
	}//sm

	// ========================================
	// MD *MIN-WIDTH*
	// ========================================
	@include breakpoint(md) {
		padding-bottom: $base-margin-row*0.5;
		padding-top: $base-margin-row*0.5;
	}//md

	// ========================================
	// LG *MIN-WIDTH*
	// ========================================
	@include breakpoint(lg) {
	}//lg

}


// ==================================
// BACKGROUND CONTAIN
// ==================================
%contain {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 50% 50%;
}

// ==================================
// BLOCK W/ MARGIN
// ==================================
%block {
	display: block;
	margin: 0 0 $base-margin 0;
	clear: both;
	list-style-type: none;
	position: relative;
}

// ==================================
// HEADER KIT
// ==================================
%header {
	font-family: $header-font-family;
	font-weight: $header-font-weight;
	letter-spacing: $header-letter-space;
	line-height: $header-line-height;
	text-transform: $header-case;
}

// ==================================
// CONTENT
// ==================================
%content {
	content: "";
	display: block;
	pointer-events: none;
}

// ==================================
// INSIDE
// ==================================
%inside {@include inside;}

// ==================================
// BLOCK IMAGE
// ==================================
%block-image {
	@include img(Block);
}

// ==================================
// LIST - ITEM
// ==================================
%list-item {
	display: list-item;
	margin-left: $base-margin*1.5;
	margin-bottom: $base-margin/2;
	ul, ol {
		padding-top: $base-margin/2;
		margin-bottom: 0;
		li {
			margin-left: $base-margin;
		}
	}
}

// ==================================
// LISTS
// ==================================
%list-bullet {@include list(bullet);}
%list-inline {@include list(inline);}
%list-digit {@include list(digit);}
%list-block {@include list(block);}

// ==================================
// TRANSITION - DEFAULT
// ==================================
%transition {
	@include transition;
}

// ==================================
// BUTTON / CTA
// ==================================
%btn {
	@include button;
	background: $base-cta-background;
	color: $base-cta-text;
	&:hover {
		background: $base-cta-hover-background;
		color: $base-cta-text;
	}
}

// ==================================
// BUTTON SMALL
// ==================================
%btn-sm {
	padding: 0.875rem 0.925rem 0.725rem;
	min-width: 36px;
	line-height: 1;
	font-size: $base-cta-font-size * 0.85;
}

// ==================================
// BASE FORM STYLE
// ==================================
%base-form {
	display: block;
	background: $white;
	color: $base-text-color;
	font-family: $base-font-family;
	line-height: $lh-base;
	font-size: $base-font-size;
	width: 100%;
	height: auto;
	border: 2px solid $grey-light;
	outline: none;
	box-shadow: none;
	border-radius: 0;
	padding: 0 1rem;
	height: 3.125rem;
	letter-spacing: $base-letter-space;
	margin: 0;
	&.error {border-color: $red;}
}

// ==================================
// CLEAR FIX
// ==================================
.clear,
.clearfix {
	clear: both;
	&:after {
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}
}
