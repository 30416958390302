// *****************************************
// INTERNAL PAGES
// code for specific pages
// *****************************************


// ==================================
// FORM TEMPLATE
// ==================================
.page-template-page-form {
	.the_content { margin-bottom: 3%; }
	aside {
		.icon {
			min-height: 2.50rem;
			padding-left: 3.750rem;
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				width: 2.50rem;
				height: 2.50rem;
				background: $base-cta-background;
				color: $base-cta-text;
				text-align: center;
				font-size: 1.375rem;
				line-height: 175%;
				border-radius: 100%;
				overflow: hidden;
			}
		}
	}

}//form-template

// ============================================================
//  INTERNAL DEFAULTS
// ============================================================
// %internal-container-defaults {
// 	#container { padding-top: calc( 6% + 79px ); }
// 	@include breakpoint(lg) {
// 		#container { padding-top: 233px; }
// 	}//lg
// }//%internal-container-defaults

%internal-main-defaults {
	.inside {
			@include ptb(4.5rem,4.5rem);
			background: $white;
			@extend %section-box-shadow;
		}//inside
		ul {
			ul { padding-bottom: 0 !important; }
		}
		@include breakpoint(xsm) {

		}//xsm

		@include breakpoint(sm) {

		}//sm

		@include breakpoint(md) {
			ul:not(.onecol) {
				// column-count: 2;
				// column-gap: 50px;
				li {
					display: inline-block;
					width: 100%;
					ul {
						column-count: 1;
						li {
							display: block !important;
						}
					}
				}
			}
		}//md

		@include breakpoint(mdlg) {

		}//mdlg

		@include breakpoint(lg) {
			ul { column-gap: 6 0px; }
		}//lg

		@include breakpoint(xlg) {

		}//xlg
}//%internal-main-defaults

// ============================================================
//  COMPACT DEFAULT - 'page-compact.php'
// ============================================================
.page-template-page-compact {
	.main { padding: 0; }
	.the_title {
		margin-bottom: $base-margin;
		padding: 4% 6%;
		background: $white;
	}//the_title
	.the_content,
	.site-map {
		margin-bottom: $base-margin*2;
		padding: 6%;
		background: $white;
	}//the_content
	&.page-id-205 {  // SITE MAP PAGE
		.the_content { display: none; }
	}
}//page-template-page-compact

// ============================================================
//  SECTION LANDING
// ============================================================
.single-section_landing {
	// @extend %internal-container-defaults;
	.sec-child-links {
		.inside {
			padding: 8% $base-margin;
			background: $grey-dark;
			color: $white;
			@extend %section-box-shadow;
			ul.child-links {
				li { padding: 10px 0 !important; }
			}//ul.child-links
			a {
				position: relative;
				padding: 0 20px;
				font-family: $headline;
				font-size: 1.8rem;
				font-weight: 400;
				text-transform: uppercase;
				color: $white;
				letter-spacing: 3px;
				line-height: 1.6;
				span { position: relative; }
				&:before {
					@include posab;
					width: 5px;
					background: $primary;
					content: '';
					transition: all 0.2s;
				}//&:before
				&:hover {
					&:before { width: 100%; }
				}//&:hover
			}//a
			.desc { padding-left: 20px; }
		}//inside
		@include breakpoint(xsm) {

		}//xsm

		@include breakpoint(sm) {

		}//sm

		@include breakpoint(md) {
			ul.child-links {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				li {
					flex: 0 0 47%;
				}
			}//ul.child-links
		}//md

		@include breakpoint(mdlg) {
			.inside { padding: 6%; }
		}//mdlg

		@include breakpoint(lg) {

		}//lg

		@include breakpoint(xlg) {

		}//xlg
	}//sec-child-links
	.sec-landing-internal {
		.sec-child-links {
			.inside {
				background: $primary;
				a:before { background: darken($primary,24%); }
			}//inside
		}//sec-child-links
	}//sec-landing-internal
	.main {
		@extend %internal-main-defaults;
	}//main

	@include breakpoint(lg) {
		#container { padding-top: calc(3% + 133px); }
	}//lg
}//single-section_landing

// ============================================================
//  INTERNAL BASIC
// ============================================================
.single-internal_basic {
	// @extend %internal-container-defaults;
	.main { @extend %internal-main-defaults; }
}

// ============================================================
//  LOCATIONS
// ============================================================
.locations {
	.location {
		margin-bottom: 4%;
		padding: 4%;
		background: $grey-xlight;
		p {
			margin-bottom: 10px;
			.phone {
				display: block;
				margin-top: 5px;
			}
		}
	}//location
	@include breakpoint(xsm) {

	}//xsm

	@include breakpoint(sm) {

	}//sm

	@include breakpoint(md) {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: stretch;
		.location {
			flex: 0 0 48%;
			max-width: 48%;
		}
	}//md

	@include breakpoint(mdlg) {

	}//mdlg

	@include breakpoint(lg) {

	}//lg

	@include breakpoint(xlg) {

	}//xlg
}//locations

// ============================================================
//  PROJECT GALLERY LANDING
// ============================================================
.internal_basic-template-project-gallery-single {
	.the_content {
		text-align: center;
		h2 { margin-bottom: 4%; }
	}//the_content
}//internal_basic-template-project-gallery-single

.project-gallery-container {
	text-align: center;
	@include breakpoint(xsm) {

	}//xsm

	@include breakpoint(sm) {
		display: flex;
		justify-content: space-around;
		align-items: stretch;
		flex-wrap: wrap;
	}//sm

	@include breakpoint(md) {

	}//md

	@include breakpoint(mdlg) {

	}//mdlg

	@include breakpoint(lg) {
		 &:after {
		  height: 0;
		  width: 31%;
		  content: "";
		}//&:after
	}//lg

	@include breakpoint(xlg) {

	}//xlg
}//project-gallery-container

ul.project-gallery {
	margin: 0 0 4% !important;
	li {
		a {
			display: flex;
			flex-direction: column;
			justify-content: center;
			min-height: 160px;
			padding: 20px;
			font-family: $headline;
			font-size: 2rem;
			font-weight: 400;
			color: $white;
			line-height: 1.2;
			@include overlay(0.4);
			&:before { transition: all 0.3s; }
			&:hover:before { opacity: 0.25; }
		}//a
		.title { position: relative; }
	}
	h5 { font-weight: 300; }
	@include breakpoint(xsm) {

	}//xsm

	@include breakpoint(sm) {
		flex: 0 0 48%;
		max-width: 48%;
	}//sm

	@include breakpoint(md) {

	}//md

	@include breakpoint(mdlg) {
		li {
			a { min-height: 240px; }
		}
	}//mdlg

	@include breakpoint(lg) {
		flex: 0 0 31%;
		max-width: 31%;
	}//lg

	@include breakpoint(xlg) {

	}//xlg
}//ul.project-gallery

// ============================================================
//  PROJECT GALLERY DETAIL
// ============================================================
.image_gallery-template-default {
	.main {
		.inside {
			@include ptb(6%,6%);
			background: $white;
		}
	}
	.the_content { margin-bottom: $base-margin*2; }
	ul.project-gallery-single {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		li {
			flex: 0 0 48%;
			margin: 0 0 2% !important;
			img { display: block; }
		}
		@include breakpoint(xsm) {
			li {
				flex: 0 0 23%;
				margin-bottom: 4%;
			}
		}//xsm

		@include breakpoint(sm) {

		}//sm

		@include breakpoint(md) {

		}//md

		@include breakpoint(mdlg) {

		}//mdlg

		@include breakpoint(lg) {

		}//lg

		@include breakpoint(xlg) {

		}//xlg
	}//ul.project-gallery-single
}//image_gallery-template-default

// ============================================================
//  FORM PAGES
// ============================================================
.page-template-page-form {
	.page-hero {
		.inside { @extend %section-box-shadow; }
	}//page-hero
	.main {
		@include ptb($base-margin-row/2,$base-margin-row);
		.row {
			@include ptb(6%,3%);
			background: $white;
			@extend %section-box-shadow;
		}
	}	//main
}//page-template-page-form

// ============================================================
//  BLOG LAYOUT STYLES
// ============================================================
.page-layout-blog {
	.page-hero {
		.inside { @extend %section-box-shadow; }
	}//page-blog
	.main {
		@include ptb($base-margin-row/2,$base-margin-row);
		.row {
			@include ptb(6%,6%);
			background: $white;
			@extend %section-box-shadow;
		}//row
		.post-excerpt {
			padding: 3%;
			.col-left { margin-bottom: 5px; }
			h2 {
				margin-bottom: 7px;
				font-size: 1.8rem;
				letter-spacing: 2px;
				a:hover { text-decoration: underline; }
			}//h2.h3
		}//post-excerpt
		.col-right {
			h4 {
				text-transform: uppercase;
				letter-spacing: 1px;
			}
			ul {
				&#menu-social-menu { @extend %ul-zero; }
				li {
					a {
						font-size: 1.2rem;
						&:hover { text-decoration: underline; }
					}
				}//li
			}//ul
		}//aside.col-right
	}//main
	@include breakpoint(xsm) {

	}//xsm

	@include breakpoint(sm) {
		.main {
			.post-excerpt {
				display: flex;
				justify-content: space-between;
				.col-left { flex: 0 0 30%; }
				.col-right {
					flex: 0 0 67%;
					&.full { flex: 0 0 100%; }
				}//col-right
			}//main
		}//main
	}//sm

	@include breakpoint(md) {

	}//md

	@include breakpoint(mdlg) {

	}//mdlg

	@include breakpoint(lg) {

	}//lg

	@include breakpoint(xlg) {

	}//xlg
}//page-layout-blog

// ============================================================
//  BLOG LANDING PAGE
// ============================================================
.post-type-archive-blog {
	&.date {
		li.widget_text { display: none; }
	}//&.date
	.page-hero {
		li.widget_text { list-style: none; }
	}//page-hero
}//post-type-archive-blog

// ============================================================
//  BLOG DETAIL PAGE
// ============================================================
.single-blog {
	.page-hero {
		.inside {
			background: $grey-dark url(../blankslate-child/img/hero-bg-dark-arrows-01.png);
			* {	color: $white; }
		}//inside
	}//page-hero
	h1 { font-size: $header-h1-font-size*.7; }
	p.h3 { font-size: $header-h3-font-size*.7; }
	p.h5 { font-size: $header-h5-font-size*.8; }
	.main {
		.image { margin-bottom: $base-margin; }
	}//main
	@include breakpoint(xsm) {

	}//xsm

	@include breakpoint(sm) {

	}//sm

	@include breakpoint(md) {

	}//md

	@include breakpoint(mdlg) {
		p.h5 { font-size: $header-h5-font-size*.7; }
	}//mdlg

	@include breakpoint(lg) {
		.page-hero {
			.inside {
				.row { width: 67%; }
			}//inside
		}//page-hero
	}//lg

	@include breakpoint(xlg) {

	}//xlg
}//single-blog
